import React, {useEffect, useState} from 'react';
import WithdrawItem from '../components/WithdrawItem';
import {useTranslation} from "../lang/TranslationContext";
import Header from "../blocks/Header";


function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
}

function formatStatus(status) {
     let back = 'In progress'
    if (status == 1)
        back = "Completed"

    if(status == 2)
        back = "Canceled"

    return back
}


const Withdraw = ({tg, openModal, user, history, fetchHistory}) => {
  const { t } = useTranslation();

    useEffect(() => {
        fetchHistory();
    }, []);



    return (
        <>
            <main>

                <section className="withdraw-section">
                    <div className="container">
                        <div className="withdraw-section__box">
                        <Header tg={tg}/>
                            <div className="wallet-info">

                                <div className="main-total">
                                    <div className="main-total__icon">
                                        <img width="33" height="33" src="img/sprite/ton.svg" alt="image"/>
                                    </div>
                                    <span className="main-total__value">
                                      <span>{user && user['balance_3']}</span> <b>TON</b>
                                    </span>
                                    <span className="main-total__currency"></span>
                                </div>

                                <button className="main-btn"  onClick={() => {
                                    openModal('wallet')
                                }}>
                                    <span className="main-btn__bg"></span>
                                    <span className="main-btn__icon">
                                      <svg width="16" height="16">
                                        <use href="img/sprite/sprite.svg#wallet"></use>
                                      </svg>
                                    </span>
                                    {t('Withdraw')}
                                </button>

                            </div>

                            <h1 className="title">
                                History
                            </h1>

                            {history.length == 0 ? (<>
                                <div className="result">
                                    {t('No transactions')}
                                </div>

                            </>) : (<>
                                <ul className="history-list history-list--mode">
                                    <li className="history-list__row history-list__row--head">
                                        <span className="history-list__name">{t('Date')}</span>
                                        <span className="history-list__name">{t('Type')}</span>
                                        <span className="history-list__name">{t('Amount')}</span>
                                        <span className="history-list__name">{t('Status')}</span>
                                    </li>

                                    {history && (
                                        <>
                                            {history.map((item) => (
                                                <>
                                                    <li className="history-list__row">
                                                        <span
                                                            className="history-list__value">{formatTimestampToDate(item.date)}</span>
                                                        <span className="history-list__value">
                                                        <svg width='20' height='20'>
                                                            <use href='img/sprite/sprite.svg#ton'></use>
                                                            </svg>
                                                            <i>{Number(item.amount).toFixed(2)} TON</i>
                                                        </span>
                                                        <span
                                                            className="history-list__value">{t(formatStatus(item.status))}</span>
                                                    </li>
                                                </>
                                            ))}
                                        </>
                                    )}


                                </ul>
                            </>)}
                        </div>
                    </div>
                </section>

            </main>
        </>
    );
};

export default Withdraw;
