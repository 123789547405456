import React, {useEffect, useState, useRef} from 'react';
import {NavLink} from 'react-router-dom';
import axios from 'axios';
import Mining from '../components/Mining';
import {useTranslation} from "../lang/TranslationContext";
import Header from "../blocks/Header";



const Home = ({tg, openModal, setCurrentBalance, setCurrentWallet}) => {

        

        const [proc, setProc] = useState(0.03);
const calculateSpeed = (power, percentMine = 100) => {
  let budget = 0.0001 * power;
  let proc_sec = proc / (86400);
  let zarabotok = budget * proc_sec;

  return zarabotok
     
}

    const { t } = useTranslation();
    const [data, setData] = useState([]);

    const [activeModal, setActiveModal] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(0);
  
  const [secondPlus, setSecondPlus] = useState(0)

  const [speed, setSpeed] = useState(0)

  useEffect(()=> {
    if(data && data.power) {
      setSpeed(calculateSpeed(Number(data.power), Number(data.mining["mining_3"])))
    }
      
  }, [data]);

  const [nowMoney, setNowMoney] = useState(0)
    useEffect(() => {
      console.log('speed', speed)
        const incrementMoney = () => {
            setNowMoney(prevMoney => Number(prevMoney) + Number(speed) / 10 );
        };

        const intervalId = setInterval(incrementMoney, 100);

        return () => clearInterval(intervalId);
    }, [speed]); 

    const fetchData = async () => {
      const initData = tg && tg.initData ? tg.initData : null;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}`, 
        {
          headers: {
            'Authorization': initData
        },
      }
      )
      .then(response => {
        setData(response.data);
        setNowMoney(response.data.user['balance_3'])
        setCurrentBalance(response.data.user['balance_3'])
        setCurrentWallet(response.data.user['wallet'])
        setProc(response.data.proc)

      }).catch(error => {
        console.log('Error fetching data:', error);
      });

    };

    useEffect(() => {
      if(tg) {
        fetchData();
      }
    }, [tg]);

    // useEffect(() => {
    //     document.body.style.overflow = 'hidden';
    //     return () => {
    //       document.body.style.overflow = 'auto';
    //     };
    //   }, []); 

    return (
        <>
        
            <main >
                <section className="miners-section">
                    <div className="container">
                        <div className="miners-section__box">
                            {/* <Header tg={tg} /> */}
                            <div class="main-top">
          <div class="main-top__bg">
            <img width='641' height='66' src='img/index/bg.png' alt='image'/>
          </div>
          <a href="https://telegra.ph/FAQ-TONEXT-09-15" target="_blank" className="main-top__btn">
                    <svg width="24" height="24">
                        <use href="img/sprite/sprite.svg#help"></use>
                    </svg>
                    FaQ
                </a>
          <div class="main-top__box">
            {/* <div class="main-top__image">
              <img width='31' height='31' src='img/index/user.png' alt='image'/>
            </div> */}
            <span class="main-top__user">{tg && tg.initDataUnsafe && tg.initDataUnsafe.user && (tg.initDataUnsafe.user.username)}</span>
          </div>
          <a href="https://t.me/tonext_support" className="main-top__btn mode">
                    <img width="59" height="41" src="img/index/faq.png" alt="image"/>
                </a>


          <div class="wallet-info wallet-info--mode" style={{minWidth: "300px"}}>
            <div class="main-total">
              <div class="main-total__icon">
                <img width='33' height='33' src='img/sprite/ton.svg' alt='image'/>
              </div>
              <span class="main-total__value">
                <span>{Number(nowMoney).toFixed(9)}</span> <b>TON</b>
              </span>
            </div>
            <div class="wallet-info__bottom">
              <span class="wallet-info__icon">
                <svg width='24' height='24'>
                  <use href='img/sprite/sprite.svg#eco'></use>
                </svg>
              </span>
              <span class="wallet-info__value"><b>{data && Number(data.power).toFixed(2)}</b> th/s</span>
            </div>
          </div>
        </div>

                            


                            <div className="miners-section__video">
                                <video width='278' height='278' playsInline muted autoPlay loop>
                                        <source src="./img/TONM_end.webm" type="video/webm"/>
                                        <source src="./img/TONM_end.mp4" type="video/mp4"/>
                                </video>
                            </div>
                            <div className="miners-section__btns">
                                <button className="main-btn" data-btn-modal="withdraw" onClick={() => {
                                    openModal('wallet')
                                }}>
                                    <span className="main-btn__bg"></span>
                                    <span className="main-btn__icon">
                                      <svg width="16" height="16">
                                        <use href="img/sprite/sprite.svg#wallet"></use>
                                      </svg>
                                    </span>
                                    {t("Withdraw")}
                                </button>
                                <button type="button" class="generate-button" onClick={() => {
                                    openModal('buy')
                                }}>
                                <span class="generate-button__bg"></span>
                                <span class="generate-button__icon">
                                        <svg width='20' height='20'>
                                        <use href='img/sprite/sprite.svg#flash'></use>
                                        </svg>
                                </span>
                                <span>{t("Buy boost")}</span>
                                </button>
                                
                            </div>


                        </div>
                    </div>


                </section>
            </main>
        </>
    );
};

export default Home;
